// CSS Imports
import '/src/css/style.css';

// NPM Imports
import 'lazysizes';
import Flickity from 'flickity';
import 'flickity-imagesloaded';

import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'

// My own module config imports
import gallery from "./modules/gallery";


window.Alpine = Alpine;
Alpine.plugin(collapse)
Alpine.start();

gallery();
