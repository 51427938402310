import baguetteBox from 'baguettebox.js';

function gallery() {
  window.addEventListener('load', function () {
    baguetteBox.run('.js-gallery', {
      onChange: function (currentIndex, imagesCount) {
        const $classes = ['animated', 'slideInRight'];

        let $currentItem = document.getElementById("baguette-img-" + (currentIndex));
        let $prevItem = document.getElementById("baguette-img-" + (currentIndex - 1));
        let $nextItem = document.getElementById("baguette-img-" + (currentIndex + 1));

        $currentItem.classList.add('is-current');

        console.log(imagesCount);

        if (currentIndex > 0) {
          $prevItem.classList.remove('is-current');
        }

        if (currentIndex < (imagesCount - 1)) {
          $nextItem.classList.remove('is-current');
        }
      },

      animation: false,
      noScrollbars: true,
      buttons: true
    });
  });
}

export default gallery;
